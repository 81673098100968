<template>
  <div>
    <div :class="miniVariant ? 'min_logo' : 'logo'">
      <img v-if="!miniVariant" alt="logo" src="../assets/logo.svg"/>
      <img v-else alt="logo" src="../assets/logo-Récupéré.png"/>
    </div>

    <v-list nav>

      <v-list-item v-if="can('admin')" to="/">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-home-variant-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('client')" to="/client">
        <v-list-item-avatar size="19" tile>
          <v-icon size="19">mdi-home-variant-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('supplier | pos')" :to="$store.state.home">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-home-variant-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | client | supplier | notification_manager')" to="/database">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-database-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Base de données</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('client')" to="/soldes">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-wallet-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Soldes envoyés</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--      <v-list-item v-if="can('admin | client')" to="/campaigns">-->
      <!--        <v-list-item-avatar size="22" tile>-->
      <!--          <v-icon size="22">mdi-message-text-outline</v-icon>-->
      <!--        </v-list-item-avatar>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Campagnes</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <v-list-item v-if="can('admin | client | supplier')" to="/transactions">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-sync</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Transactions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | support | order_manager')" to="/voucher-order">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-order-bool-descending-variant</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Commandes Vouchers</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group v-if="can('admin')" color="light">
        <v-list-item slot="activator" :class="getPadding">
          <v-list-item-avatar size="22" tile>
            <v-icon size="22">mdi-shield-search</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Analyses</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/analyses">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Analyses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/balance">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Balance Services</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/balance/history">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Historiques comptes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/anomalies">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Anomalies Balance</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/anomaly/cards">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Anomalies Cartes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/partner-analyses">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Analyses partenaires</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list-group>

      <v-list-item v-if="can('admin')" to="/flexy-offers">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-tag-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Offres flexy</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group v-if="can('admin | supplier | order_manager | stock_manager')" color="light">
        <v-list-item slot="activator" :class="getPadding">
          <v-list-item-avatar size="22" tile>
            <v-icon size="22">mdi-ticket-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Vouchers</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="can('admin | supplier | order_manager | stock_manager')" to="/vouchers">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vouchers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/analyses_vouchers">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Analyses Vouchers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item v-if="can('admin')" to="/services">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-layers-triple-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/fees">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-cards-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Frais de service</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group v-if="can('admin')" color="light">
        <v-list-item v-if="can('admin')" slot="activator" :class="getPadding">
          <v-list-item-avatar size="22" tile>
            <v-icon size="22">mdi-card-multiple-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Cartes</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/cards">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cartes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="can('admin')" to="/card_types">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Types Cartes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item v-if="can('admin | client')" to="/publicities">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-bullhorn-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Publicités \ Pop-up</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/occasion_cards">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-party-popper</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Cartes d'occasion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | notification_manager')" to="/notices">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-bell-cog-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-item v-if="can('admin | supplier | order_manager')" to="/recoveries">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-cash-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Recouvrements</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-item v-if="can('admin')"
                   to="/cib-recoveries">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-cash-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Recouvrements CIB</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-group v-if="can('supplier')" color="light">
        <v-list-item slot="activator" :class="getPadding">
          <v-list-item-avatar size="22" tile>
            <v-icon size="22">mdi-storefront-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Boutiques</v-list-item-title>
        </v-list-item>

        <v-list-item to="/partner-vouchers/my-stores">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mes boutiques</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/partner-vouchers/stores">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gestion des boutiques</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/partner-vouchers/statistics">
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Statistiques globale</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item exact
                   v-if="can('store')"
                   :to="'/partner-vouchers/store/' + $store.state.user.user.id"
      >
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-home-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item exact
                   v-if="can('store')"
                   :to="'/partner-vouchers/store/' + $store.state.user.user.id+'/scan-history'"
      >
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-history</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>H. des scans</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item exact
                   v-if="can('store')"
                   :to="'/partner-vouchers/store/' + $store.state.user.user.id+'/payment-history'"
      >
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-credit-card-clock-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>H. des paiements</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/quotas">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-rotate-right</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Quotas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/quotas-cib">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-rotate-right</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Quotas CIB</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/promotions">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-wallet-giftcard</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Promotions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/promo-codes">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-barcode-scan</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Codes promo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | stock_manager')" to="/cashback">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-cash-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Cashback</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | stock_manager')" to="/e-payments">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-currency-usd</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>E-Paiements</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | order_manager | stock_manager')" to="/paying-bills">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-cash-multiple</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Paiement des factures</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="$store.state.user?.user?.account?.pending_paying_bills">
          <v-list-item-action-text>
            <div class="primary rounded pa-1 white--text text-no-wrap"
                 :style="{ minWidth: '22px', textAlign: 'center' }">
              {{ $store.state.user?.user?.account?.pending_paying_bills }}
            </div>
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>


      <v-list-item v-if="can('admin | support | order_manager | stock_manager | supplier')" to="/support">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-phone-log-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-item v-if="can('supplier_manager')" to="/supplier-manager-services">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-layers-triple</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-item v-if="can('client')" to="/accounts">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-box-multiple-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Comptes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | client | supplier | support | supplier_manager')" to="/users">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-multiple-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Utilisateurs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | stock_manager')" to="/users-verification">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-check-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Vérification des utilisateurs</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="$store.state.user?.user?.account?.pending_user_verifications">
          <v-list-item-action-text>
            <div class="primary rounded pa-1 white--text text-no-wrap"
                 :style="{ minWidth: '22px', textAlign: 'center' }">
              {{ $store.state.user?.user?.account?.pending_user_verifications }}
            </div>
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/referral-codes">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-code-equal</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Codes de parrainage</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin | stock_manager | support')" to="/disable_requests">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-off-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Demandes suppression</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/service/commissions">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-percent-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Commissions Service</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('booster')" to="/boostings">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-rocket-launch-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Boostes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-group v-if="can('admin | support')" color="light">

        <v-list-item slot="activator" :class="getPadding">
          <v-list-item-avatar size="22" tile>
            <v-icon size="22">mdi-alert-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Erreurs signalés</v-list-item-title>
        </v-list-item>

        <v-list-item to="/errors-reported" exact>
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Liste</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/errors-reported/list-errors" exact>
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Problèmes présentés</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>

      <v-list-group v-if="can('admin') || $store.state.user.user.id === 16428" color="light">

        <v-list-item slot="activator" :class="getPadding">
          <v-list-item-avatar size="22" tile>
            <v-icon size="22">mdi-form-select</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>OPUS</v-list-item-title>
        </v-list-item>

        <v-list-item to="/opus/campaigns" exact>
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Campagnes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/opus/campaigns/create" exact>
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nouvelle campagne</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/opus/backoffice" exact>
          <v-list-item-icon>
            <v-icon class="ml-1" size="11">mdi-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Backoffice</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item v-if="!can('admin')" to="/myAccount">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-account-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Mon compte</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="can('admin')" to="/settings">
        <v-list-item-avatar size="22" tile>
          <v-icon size="22">mdi-cog-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </div>
</template>

<script>
export default {
  props: ["miniVariant"],
  computed: {
    getPadding() {
      if (this.miniVariant) {
        return "padding-10px";
      }
      return "no-padding";
    },
  },
};
</script>

<style scoped>
.padding-10px {
  padding-left: 10px !important;
}

.no-padding {
  padding-left: 0 !important;
}

.padding-submenu {
  padding-left: 30px !important;
}

.v-list-item--active.no-padding {
}

.v-list-group__items {
}

.theme--dark.v-list-item--active::before,
.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item:focus::before {
  opacity: 0;
}
</style>